import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)

if(window.location.pathname != "/cookies-policy"){
    function noScroll() {
        window.scrollTo(0, 0);
    }
    
    const avWrapper = document.getElementById("avWrapper")
    const avBtn = document.getElementById("avBtn")
    const avBtnDecline = document.getElementById("avBtnDecline")
    const avHeadline = document.getElementById("avHeadline")
    
    
    function getCookie(cName) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded .split('; ');
      let res;
      cArr.forEach(val => {
          if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res;
    }
    
    avBtn.addEventListener("click", setAVCookie);
    avBtnDecline.addEventListener("click", declineMessage);
    
    function setAVCookie() {
      document.cookie = "ageVerification=true";
      avWrapper.classList.add('hidden')
      cookieCheck('ageVerification')
    }
    
    function declineMessage() {
      avHeadline.innerHTML = "Sorry, you are not old enough to view this content."
    }
    
    
      var cookieCheck = (cName) => {
    
      if (getCookie(cName) == "true") {
        // console.log(getCookie(cName));
        // console.log("Cookie is set");
        
        window.removeEventListener('scroll', noScroll);
      } else {
        window.addEventListener('scroll', noScroll);
        // console.log(getCookie(cName));
        // console.log("Cookie is not set");
        avWrapper.classList.remove('hidden')
        
      }
    }
    cookieCheck('ageVerification')
    
}


let mm = gsap.matchMedia();
let bannerCan
let squeeze

    

    mm.add("(min-width: 768px)", () => {
        bannerCan = document.querySelector(".banner-can")
        squeeze = document.getElementById("squeeze-home")

        if(bannerCan && squeeze) {
            // Homepage Animation
            gsap.set(bannerCan,  { x: '60vw'})
            gsap.set(squeeze,  { x: 500 })
            gsap.timeline({
                scrollTrigger: {
                trigger: "#homepage-banner",
                start: "center center",
                end: "bottom top",
                scrub: true,
                pin: true,
                invalidateOnRefresh: true
                }
            })
            .to(bannerCan,  { x: '2vw', rotate: "-15deg" })
            .to("#home-banner-panel-2",  { opacity: 1}, "<")
            .to("#home-banner-panel-1",  { opacity: 0}, "<")
            .to("#home-banner-panel-2-inner",  { opacity: 1})
            .to(squeeze, { x: 0}, "<")
        }
        

        
    });
    mm.add("(max-width: 768px)", () => {
        bannerCan = document.querySelector(".banner-can")
        squeeze = document.getElementById("squeeze-home")

        if(bannerCan && squeeze) {
            // Homepage Animation
            
            gsap.set(squeeze,  { x: 500 })
            gsap.timeline({
                scrollTrigger: {
                trigger: "#homepage-banner",
                start: "center center",
                end: "bottom top",
                scrub: true,
                pin: true,
                invalidateOnRefresh: true
                }
            })
            .to(bannerCan,  { x: 0, rotate: "-15deg", bottom: -500  })
            .to("#home-banner-panel-2",  { opacity: 1}, "<")
            .to("#home-banner-panel-1",  { opacity: 0}, "<")
            .to("#home-banner-panel-2-inner",  { opacity: 1})
            .to(squeeze, { x: 0}, "<")
        }
        
    })

    // social row animate
    // const socialRow = document.getElementById("social-row")

    // if(socialRow){
    //     // Social Row Animation
    //     gsap.timeline({
    //         scrollTrigger: {
    //         trigger: "#social-row",
    //         start: "top bottom",
    //         end: "bottom top",
    //         scrub: true,
    //         invalidateOnRefresh: true
    //         }
    //     })
    //     .to("#social-row-inner",  { x: -200 })
    // }

    // markets row animate
    const marketsRow = document.getElementById("markets-row")

    if(marketsRow){
        // Social Row Animation
        gsap.timeline({
            scrollTrigger: {
            trigger: "#markets-row",
            start: "top bottom",
            end: "bottom top",
            scrub: true,
            invalidateOnRefresh: true
            }
        })
        .to("#markets-row-inner",  { x: -200 })
    }
    
    

window.addEventListener("resize", function () {
    ScrollTrigger.refresh();
  });

// Testimonial Swiper
const testimonialSwiper = new Swiper('.testimonial-swiper', {
    
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    navigation: {
        nextEl: ".white-swiper-button-next",
        prevEl: ".white-swiper-button-prev",
    }
    
});

// Social Swiper
const socialSwiper = new Swiper('.social-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {

        768: {
            slidesPerView: 1.5,
        },
        1024: {
            slidesPerView: 2.2,
        },
        1450: {
            slidesPerView: 3.2,
        },
        1800: {
            slidesPerView: 4.2,
        }

    },
    navigation: {
        nextEl: ".white-social-swiper-button-next",
        prevEl: ".white-social-swiper-button-prev",
    }
    
  });

// Blog Swiper
const blogSwiper = new Swiper('.blog-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1.2,
    spaceBetween: 20,
    breakpoints: {

        768: {
            slidesPerView: 1.5,
        },
        1024: {
            slidesPerView: 2.2,
        },
        1300: {
            slidesPerView: 2.7,
        },
        1500: {
            slidesPerView: 3.2,
        }

    },
    navigation: {
        nextEl: ".white-blog-swiper-button-next",
        prevEl: ".white-blog-swiper-button-prev",
    }
    
  });

// Characteristics Swiper
const characteristicsSwiper = new Swiper('.characteristics-swiper', {
    // Optional parameters
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    centerInsufficientSlides:true,
    breakpoints: {

        768: {
            slidesPerView: 1.7,
        },
        1024: {
            slidesPerView: 2.7,
        },
        1300: {
            slidesPerView: 3.5,
        },
        1500: {
            slidesPerView: 3.7,
        }

    },
    navigation: {
        nextEl: ".white-characteristics-swiper-button-next",
        prevEl: ".white-characteristics-swiper-button-prev",
    }
    
  });

// Product Image Swiper
const productImageSwiper = new Swiper('.product-image-swiper', {
    spaceBetween: 10
});

const productThumbnails = document.querySelectorAll(".thumbnail-imgs")

if(productThumbnails.length){
    for (let i = 0; i < productThumbnails.length; i++) {
        const el = productThumbnails[i];
       
        el.addEventListener("click", () => {
            productImageSwiper.slideTo(i)
        })
    }
    productImageSwiper.on('activeIndexChange', function(e) {
        console.log(productImageSwiper.activeIndex)
        for (let i = 0; i < productThumbnails.length; i++) {
            const el = productThumbnails[i];
            
            if(i === productImageSwiper.activeIndex){
                el.classList.remove("border-white")
                el.classList.add("border-green")
            }else{
                el.classList.add("border-white")
                el.classList.remove("border-green")
            }
        }
    });
}



// productImageSwiperThumbnails.disable()
// productImageSwiperThumbnails.controller.control = productImageSwiper;  
// productImageSwiper.controller.control = productImageSwiperThumbnails;


// Donkeytail Pin Functionality
const pins = document.querySelectorAll(".pins")
const pinContainers = document.querySelectorAll(".pin-containers")

if(pins && pinContainers) {
    for (let i = 0; i < pins.length; i++) {
        const pin = pins[i];
        pin.addEventListener("mouseover", () => {
            for (let e = 0; e < pins.length; e++) {
                const el = pins[e];
                pin.classList.add("bg-white")

                pinContainers[i].classList.add("opacity-0")
                pinContainers[e].classList.remove("opacity-100")
            }
            

            pin.classList.remove("bg-white")

            pinContainers[i].classList.add("opacity-100")
            pinContainers[i].classList.remove("opacity-0")
        })
        pin.addEventListener("mouseout", () => {
            for (let e = 0; e < pins.length; e++) {
                const el = pins[e];
                pin.classList.add("bg-white")

                pinContainers[i].classList.add("opacity-0")
                pinContainers[e].classList.remove("opacity-100")
            }
        })
    }
}

// Explative Asterisk
const exAsteriskBox = document.querySelector(".ex-asterisk-green")

if(exAsteriskBox) {
    exAsteriskBox.innerHTML = '<svg class="inline-block green-asterisk" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.8886 0H39.9331L36.7242 24.8363L59.0084 14.3216L64 29.5497L39.7549 34.2632L56.6908 52.3918L43.6769 62L31.9109 40.0643L20.1448 62L7.30919 52.3918L24.0668 34.2632L0 29.5497L4.81337 14.3216L26.9192 25.0175L23.8886 0Z" fill="#006838"/></svg>'
    
}

// Replace newsletter submit content
const newsletterSubmit = document.getElementById("newsletter-submit")

if(newsletterSubmit){
    newsletterSubmit.innerHTML = '<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.15137H21M21 9.15137L13.8333 1.65137M21 9.15137L13.8333 16.6514" stroke="#006838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
}

// header dropdown
const headerParents = document.querySelectorAll(".header-parent-box")
const headerChildren = document.querySelectorAll(".header-children")

if(headerChildren.length){
    for (let index = 0; index < headerParents.length; index++) {
        const el = headerParents[index];
        
        el.addEventListener("mouseover", () => {
            headerChildren[index].classList.remove("hidden")
        })
        el.addEventListener("mouseout", () => {
            headerChildren[index].classList.add("hidden")
        })
    }
}


// scrollback functionality

function debounce(func, timeout = 10){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

const header = document.getElementById("header")

var current = window.scrollY
var paddedUp = 0
var paddedDown = 0
if(window.scrollY > 50) {
    header.classList.add("bg-white")
} else {
    header.classList.remove("bg-white")
}

if(window.location.pathname === "/snag-a-southside"){
    header.classList.remove("text-white")
    header.classList.add("text-green")
}


function saveInput(){
  
    if(window.scrollY > 50) {
        if(window.scrollY < current){
          if(window.scrollY < paddedUp) {
          header.classList.remove("scroll-down")
          header.classList.add("scroll-up")
          }
          paddedDown = window.scrollY + 50
          
        } else {
          if(window.scrollY > paddedDown) {
            header.classList.remove("scroll-up")
            header.classList.add("scroll-down")
            
              header.classList.add("bg-white")
          
            paddedUp = window.scrollY - 50
          }
         
        }
        // if(window.location.pathname === "snag-a-southside"){
        //     header.classList.remove("text-green")
        //     header.classList.remove("text-white")
        // }
        if(window.location.pathname !== "/snag-a-southside"){
            header.classList.remove("text-white")
            header.classList.add("text-green")
        }
        

        current = window.scrollY
    } else {
        // if(window.location.pathname === "snag-a-southside"){
        //     header.classList.remove("text-green")
        //     header.classList.add("text-white")
        // }
    if(window.location.pathname !== "/snag-a-southside"){
        header.classList.add("text-white")
        header.classList.remove("text-green")
    }

    header.classList.remove("scroll-down")
    header.classList.add("scroll-up")
    header.classList.remove("bg-white")
    }
 
}

const logoScroll = debounce(() => saveInput());
window.addEventListener("scroll", logoScroll);


// mobile nav functionality
const mobileNav = document.getElementById("mobile-nav-menu")
const navBtn = document.getElementById("mobile-nav-btn")
const navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
  navEx.addEventListener("click", function() {
      if(mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.remove("mobile-nav-active")
        
      }   
  })
}
if(navBtn){
navBtn.addEventListener("click", function() {
    if(!mobileNav.classList.contains("mobile-nav-active")) {
      mobileNav.classList.add("mobile-nav-active")
      
    }
})
}


// SHopify buy btn

const buyBtnContainer = document.getElementById("buy-btn-container")

if (window.ShopifyBuy && buyBtnContainer) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
} else {
    
}
  
function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
}

function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: 'e33fd3-3.myshopify.com',
      storefrontAccessToken: 'a66d2d09f1fa6f8cb8518834f6aede70',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
            id: '6854086787127',
            node: document.getElementById('product-component-1698079106735'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
                "product": {
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                            "max-width": "calc(25% - 20px)",
                            "margin-left": "20px",
                            "margin-bottom": "50px"
                            },
                            "text-align": "left"
                        },
                        "button": {
                            "font-family": "Jost, sans-serif",
                            "color": "#006838",
                            ":hover": {
                                "color": "#fffaea",
                                "background-color": "#006838"
                            },
                            "background-color": "#fffaea",
                            ":focus": {
                                "background-color": "#e6e1d3"
                            },
                            "border-radius": "25px",
                            "padding-left": "35px",
                            "padding-right": "35px",
                            "font-weight": "700",
                            "text-transform": "uppercase",
                            "border": "3px solid #006838"
                        }
                        },
                        "contents": {
                            "img": false,
                            "button": true,
                            "buttonWithQuantity": false,
                            "title": false,
                            "price": false
                        },
                        "text": {
                            "button": "Add to cart"
                        },
                        "googleFonts": [
                            "Jost"
                        ]
                    },
                    "productSet": {
                        "styles": {
                        "products": {
                            "@media (min-width: 601px)": {
                            "margin-left": "-20px"
                            }
                        }
                    }
                },
                "modalProduct": {
                    "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                    },
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px"
                        }
                    },
                    "button": {
                        "font-family": "Jost, sans-serif",
                        "color": "#006838",
                        ":hover": {
                        "color": "#006838",
                        "background-color": "#e6e1d3"
                        },
                        "background-color": "#fffaea",
                        ":focus": {
                        "background-color": "#e6e1d3"
                        },
                        "border-radius": "25px",
                        "padding-left": "35px",
                        "padding-right": "35px"
                    }
                    },
                    "googleFonts": [
                    "Jost"
                    ],
                    "text": {
                    "button": "Add to cart"
                    }
                },
                "option": {},
                "cart": {
                    "styles": {
                        "button": {
                            "font-family": "Jost, sans-serif",
                                "color": "#006838",
                                ":hover": {
                                    "color": "#fffaea",
                                    "background-color": "#006838"
                                },
                                "background-color": "#fffaea",
                                ":focus": {
                                    "background-color": "#e6e1d3"
                                },
                                "border-radius": "25px",
                                "padding-left": "35px",
                                "padding-right": "35px",
                                "font-weight": "700",
                                "text-transform": "uppercase",
                                "border": "3px solid #006838"
                        },
                        "title": {
                            "color": "#006838"
                        },
                        "header": {
                            "color": "#006838"
                        },
                        "lineItems": {
                            "color": "#006838"
                        },
                        "subtotalText": {
                            "color": "#006838"
                        },
                        "subtotal": {
                            "color": "#006838"
                        },
                        "notice": {
                            "color": "#006838"
                        },
                        "currency": {
                            "color": "#006838"
                        },
                        "close": {
                            "color": "#006838",
                            ":hover": {
                            "color": "#006838"
                            }
                        },
                        "empty": {
                            "color": "#006838"
                        },
                        "noteDescription": {
                            "color": "#006838"
                        },
                        "discountText": {
                            "color": "#006838"
                        },
                        "discountIcon": {
                            "fill": "#006838"
                        },
                        "discountAmount": {
                            "color": "#006838"
                        },
                        "cart": {
                            "background-color": "#fffaea"
                        },
                        "footer": {
                            "background-color": "#fffaea"
                        }
                    },
                    "text": {
                        "total": "Subtotal",
                        "button": "Checkout"
                    },
                    "googleFonts": [
                        "Jost"
                    ]
                },
                "toggle": {
                    "styles": {
                    "toggle": {
                        "font-family": "Jost, sans-serif",
                        "background-color": "#fffaea",
                        ":hover": {
                        "background-color": "#e6e1d3"
                        },
                        ":focus": {
                        "background-color": "#e6e1d3"
                        }
                    },
                    "count": {
                        "color": "#006838",
                        ":hover": {
                        "color": "#006838"
                        }
                    },
                    "iconPath": {
                        "fill": "#006838"
                    }
                    },
                    "googleFonts": [
                        "Jost"
                    ]
                },
                "lineItem": {
                    "styles": {
                    "variantTitle": {
                        "color": "#006838"
                    },
                    "title": {
                        "color": "#006838"
                    },
                    "price": {
                        "color": "#006838"
                    },
                    "fullPrice": {
                        "color": "#006838"
                    },
                    "discount": {
                        "color": "#006838"
                    },
                    "discountIcon": {
                        "fill": "#006838"
                    },
                    "quantity": {
                        "color": "#006838"
                    },
                    "quantityIncrement": {
                        "color": "#006838",
                        "border-color": "#006838"
                    },
                    "quantityDecrement": {
                        "color": "#006838",
                        "border-color": "#006838"
                    },
                    "quantityInput": {
                        "color": "#006838",
                        "border-color": "#006838"
                    }
                    }
                }
            },
        });
    });
}


// Maze Functionality
let app = {};
const mapWrapper = document.getElementById("map-wrapper")
if(mapWrapper){
    (function(context) {

        /*
         *  Build an array of levels.
         *  This will scale better if it is stored in a separate JSON File.
         */
        let levels = [];
        levels[0] = {
          map:[
             [1,0,1,1,1,1,1,1,1,1],
             [1,0,1,1,1,1,0,0,0,1],
             [1,0,0,0,0,0,0,1,0,1],
             [1,1,0,1,1,1,0,1,0,1],
             [1,1,0,0,0,1,0,1,0,1],
             [1,1,1,1,0,1,0,1,0,0],
             [0,0,0,0,1,1,0,1,1,1],
             [1,0,1,0,0,0,0,0,0,1],
             [1,0,1,1,1,1,0,1,0,0],
             [1,0,1,0,0,0,0,1,1,1]
          ],
        
          player:{
              x:1,
              y:0
          },
          goal:{
              x:1,
              y:9
          },
          theme:'default',
        };
       
      
      /*
       *  The game object constructor.
       *  @param {String} id - the id of the game container DOM element.
       *  @param {Object} level - the starting level of the game.
       */
      function Game(id,level) {
        
        this.el = document.getElementById(id);
        
        // level addition
        this.level_idx = 0;
        
        // establish the basic properties common to all this objects.
        this.tileTypes = ['floor','wall'];
        this.tileDim = 10;
        // inherit the level's properties: map, player start, goal start.
        this.map = level.map;
        
        // level switch
        this.theme = level.theme;
        
        // make a copy of the level's player.
        this.player = {...level.player};
        
        // create a property for the DOM element, to be set later.
        this.player.el = null;
        
        // make a copy of the goal.
        this.goal = {...level.goal};
      }
      
      // reset height of maze based on width
      
      if(mapWrapper){
      
          mapWrapper.style.height = `${mapWrapper.offsetWidth}px`
      
          window.addEventListener("resize", function () {
              mapWrapper.style.height = `${mapWrapper.offsetWidth}px`
          })
      }
      
      
      /*
       * Create a tile or sprite <div> element.
       * @param {Number} x - the horizontal coordinate the 2D array.
       * @param {Number} y - the vertical coordinate in the 2D array.
       */
      Game.prototype.createEl = function(x,y,type) {
         // create one tile.
        let el = document.createElement('div');
             
        // two class names: one for tile, one or the tile type.
        el.className = type;
        
        // set width and height of tile based on the passed-in dimensions.
        el.style.width = el.style.height = this.tileDim + '%';
        
        // set left positions based on x coordinate.
        el.style.left = x*this.tileDim + '%';
        
        // set top position based on y coordinate.
        el.style.top = y*this.tileDim + '%';
            
        return el;
      }
      
      /*
       * Applies the level theme as a class to the game element. 
       * Populates the map by adding tiles and sprites to their respective layers.
       */
      Game.prototype.populateMap = function() {
        
        // add theme call
        this.el.className = 'game-container w-full ' + this.theme;
      
        // make a reference to the tiles layer in the DOM.
        let tiles = this.el.querySelector('#tiles');
        
        // set up our loop to populate the grid.
        for (var y = 0; y < this.map.length; ++y) {
          for (var x = 0; x < this.map[y].length; ++x) {
            
             let tileCode = this.map[y][x];
      
              // determine tile type using code
              // index into the tileTypes array using the code.
             let tileType = this.tileTypes[tileCode];
            
             // call the helper function
             let tile = this.createEl(x,y,tileType);
             
             // add to layer
             tiles.appendChild(tile);
          }
        }
      }
      
      /*
       * Place the player or goal sprite.
       * @param {String} type - either 'player' or 'goal', used by createEl and becomes DOM ID
       */
      Game.prototype.placeSprite = function(type) {
        
        // syntactic sugar
        let x = this[type].x
        
        let y = this[type].y;
        
        // reuse the createTile function
        let sprite  = this.createEl(x,y,type);
        
        sprite.id = type;
        
        // set the border radius of the sprite.
        sprite.style.borderRadius = this.tileDim + '%';
        
        // get half the difference between tile and sprite.
        
        // grab the layer
        let layer = this.el.querySelector('#sprites');
        
        layer.appendChild(sprite);
        
        return sprite;
      }
      
      /*
       * Triggers a collide animation on the player sprite.
       */
      Game.prototype.collide = function() {
        this.player.el.className += ' collide';
        
        let obj = this;
        
        window.setTimeout(function() {
        obj.player.el.className = 'player';
        },200);
        
        return 0;
        
      };
      /*
       * Moves the player sprite left.
       */
      Game.prototype.moveLeft = function() {
          // if at the boundary, return
          if (this.player.x == 0) {
              this.collide();
              return;
          }
          // itentify next tile
          let nextTile = this.map[this.player.y][this.player.x-1];
        
          // if next tile is a wall, add collide effect and return
          if (nextTile ==1) {
              this.collide();
              return;
          }
          // change coordinates of player object
          this.player.x -=1;
          // update location of DOM element
          this.updateHoriz();
      };
      /*
       * Moves the player sprite up.
       */
      Game.prototype.moveUp = function() {
        if (this.player.y == 0) {
              // at end: these could be combined
              this.collide();
              return;
        }
            
        let nextTile = this.map[this.player.y-1][this.player.x];
        if (nextTile ==1) {
              this.collide();
              return;
        }
        this.player.y -=1;
        this.updateVert();
        
      };
      /*
       * Moves the player sprite right.
       */
      Game.prototype.moveRight = function()  {
         if (this.player.x == this.map[this.player.y].length-1) {
              this.collide();
              return;
         }
         let nextTile = this.map[this.player.y][this.player.x+1];
              
         if (nextTile ==1) {
              this.collide()
              return;
         }
         this.player.x += 1;
         
         this.updateHoriz();
      };
      /*
       * Moves player sprite down.
       */
      Game.prototype.moveDown = function()  {
        if (this.player.y == this.map.length-1) {
              this.collide();
              return;
         }
         // find the next tile in the 2D array.
              
         let nextTile = this.map[this.player.y+1][this.player.x];
          if (nextTile ==1) {
              this.collide()
              return;
         }
         this.player.y += 1;
         this.updateVert();
        
      };
      /* 
       *  Updates vertical position of player sprite based on object's y coordinates.
       */
      Game.prototype.updateVert = function() { 
           this.player.el.style.top = this.player.y * this.tileDim+ '%';
      };
      /* 
       *  Updates horizontal position of player sprite based on object's x coordinates.
       */  
      Game.prototype.updateHoriz = function() {
           this.player.el.style.left = this.player.x * this.tileDim + '%'; 
      };
      /*
       * Moves player based on keyboard cursor presses.
       */
      Game.prototype.movePlayer = function(event) {
          event.preventDefault();
          
          if (event.keyCode < 37 || event.keyCode > 40) {
            return;
          }
      
          switch (event.keyCode) { 
            case 37:
            this.moveLeft();
            break;
            
            case 38:       
            this.moveUp();
            break;
      
            case 39:
            this.moveRight();
            break;
              
            case 40:
            this.moveDown();
            break;
          }
       }
      /*
       * Check on whether goal has been reached.
       */
      Game.prototype.checkGoal = function() {
           let body = document.querySelector('body');
        
           if (this.player.y == this.goal.y &&
             this.player.x == this.goal.x) {
             
             body.className = 'success';
           }
           else {
             body.className = '';
           }
      }
      /*
       * Changes the level of the game object.
       */
      Game.prototype.changeLevel = function() {
          
          // update the level index.
          this.level_idx ++;
      
          // if higher than max index, set back to zero.
             if (this.level_idx > levels.length -1) {
               this.level_idx = 0;
          }
          
          // get the level at this index.
          let level = levels[this.level_idx];
          
          // sync the map with the level map.
          this.map = level.map;
          // sync the theme with the level theme.
          this.theme = level.theme;
      
          // make a copy of the level's player object, since x and y change during the game.
          this.player = {...level.player};
      
          // make a copy of the level's goal object, since x and y change between levels.
          this.goal = {...level.goal};
       }
      
       /*
        * If goal has been reached, 
        */
       Game.prototype.addMazeListener = function() {
      
         // grab the map
      
         let map = this.el.querySelector('.game-map');
      
         // grab reference to game object since we are going into a function 
         // and "this" will no longer refer to the game object
      
         let obj = this;
      
         // if game board is clicked or tapped, see if we should change levels
         map.addEventListener('mousedown',function(e) {
           
             // if not at the goal, then get outta here
             if (obj.player.y != obj.goal.y ||
             obj.player.x != obj.goal.x) {
               return;
             }
             // change level of game object by changing it's properties
             obj.changeLevel();
             
             // get the two layers
             let layers = obj.el.querySelectorAll('.layer');
            
             // clear tiles and sprites from layers
             for (layer of layers) {
                 layer.innerHTML = '';
             }
             
             // place the new level.
             obj.placeLevel();
           
             // check the goal to reset the message.
             obj.checkGoal();
            
         });
       };
      
      /*
       *  Responds to a keydown event by moving the player and checking the goal.
       */
      Game.prototype.keyboardListener = function() {
        document.addEventListener('keydown', event => {
            this.movePlayer(event);
            this.checkGoal();
        });
        
       }
       /*
        * Adds mouse down listeners to buttons
        */
       Game.prototype.buttonListeners = function() {
         let up = document.getElementById('up');
         let left = document.getElementById('left');
         let down = document.getElementById('down')
         let right = document.getElementById('right');
         
         // the sprite is out of date
         let obj = this;
         up.addEventListener('mousedown',function() {
        
           obj.moveUp();
           obj.checkGoal();   
         });
          down.addEventListener('mousedown',function() {
           obj.moveDown();
           obj.checkGoal();   
         });
          left.addEventListener('mousedown',function() {
           obj.moveLeft();
           obj.checkGoal();   
         });
          right.addEventListener('mousedown',function() {
           obj.moveRight();
           obj.checkGoal();   
         });
         
       }
        
      /*
       * Sets the message of the text element.
       * @param {String} msg - The message to be printed.
       */
       Game.prototype.setMessage = function(msg) { 
         let text_el = this.el.querySelector('.text');
         text_el.textContent = msg;
       };
      
       /*
        * Sizes up the map based on array dimensions.
        */
       Game.prototype.sizeUp = function() {
        
        // inner container so that text can be below it
        let map  = this.el.querySelector('.game-map');
        
        // inner container, height. Need this.map
         
      };
        
      
      /*
       * Populates the map.
       * Sizes up the map based on array dimensions.
       * Gives the goal and player some references.
       */ 
       Game.prototype.placeLevel = function() {
          this.populateMap();
          
          this.sizeUp();
         
          this.placeSprite('goal');
          
          // we want the DOM element that gets returned...
          let playerSprite = this.placeSprite('player');
         
          // ..so we can store it in the playerSprite element.
          this.player.el = playerSprite;
         
       }
       /*
        *  Add keyboard, button, and maze tap listeners
        */
       Game.prototype.addListeners = function() {
          
          this.keyboardListener();
          
          this.buttonListeners();
          
          // changing levels
          this.addMazeListener();
        }
        
        /*
         *  Initialization function called once
         */
        context.init = function () {
          
          let myGame = new Game('game-container-1',levels[0]);
          
          // encapsulate for multi-level
          myGame.placeLevel();
          
          // add listeners
          myGame.addListeners();
          
        }
      })(app);
      
      /*
       * Tell app to activate the init() function.
       */
      
      app.init();
      
      
}

// replace drag and drop placeholder
const dAndDPlaceholder = document.querySelector(".freeform-file-drag-and-drop__placeholder")
if(dAndDPlaceholder) {
    dAndDPlaceholder.innerHTML = "Show off your Southside"
}

// Video optimization

const videos = document.querySelectorAll(".videoplayer")
if(videos.length){
    for (let i = 0; i < videos.length; i++) {
        const el = videos[i];
        var videoWidth = 1280;
        var  video = $(el).attr("srcdesk");
        if($(window).width() < 721) {
            videoWidth = 720;
            video = $(el).attr("srcmob");
        }
        $(el).attr("width", videoWidth).append('<source src="' + video + '" type="video/mp4" >')
    }
}

// featured product description gradient
const gradient = document.getElementById("featured-product-description-gradient")
const scrollBox = document.getElementById("featured-product-description-box")

function atBottom(el) {
    let sh = el.scrollHeight,
        st = el.scrollTop,
        ht = el.offsetHeight;
    return ht == 0 || st == sh - ht; 
}

if(scrollBox && gradient){
    if(scrollBox.scrollHeight === scrollBox.clientHeight){
        gradient.classList.add("opacity-0")
    }else{
        scrollBox.addEventListener("scroll", () => {
            if(atBottom(scrollBox)){
                gradient.classList.add("opacity-0")
            } else {
                gradient.classList.remove("opacity-0")
            }
        })
    }


   
}




// site overlay
const overlayTimeline = gsap.timeline({
    delay:1
  })
  overlayTimeline
  .to("body", {opacity:1})